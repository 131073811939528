import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useParams, useLocation } from 'react-router-dom';
import './App.css';



function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Home({ dataFile }) {
  useEffect(() => {
    document.title = 'Toaru-if-mobile-db';
  }, []);

  const [units, setUnits] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [colorFilter, setColorFilter] = useState('');
  const [sideFilter, setSideFilter] = useState('');
  const [Attack_typeFilter, setAttack_TypeFilter] = useState('');
  const [bannerFilter, setBannerFilter] = useState('');
  const [directionFilter, setDirectionFilter] = useState('');
  const [supercolorFilter, setSuperColorFilter] = useState('');
  const [showDetails, setShowDetails] = useState(false);


  useEffect(() => {
    fetch(`/${dataFile}.json`)
      .then(response => response.json())
      .then(data => setUnits(data))
      .catch(error => console.error('Error fetching data:', error));
  }, [dataFile]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleColorFilter = (event) => {
    setColorFilter(event.target.value);
  };

  const handleAttack_TypeFilter = (event) => {
    setAttack_TypeFilter(event.target.value);
  };
  
  const handleSideFilter = (event) => {
    setSideFilter(event.target.value);
  };

  const handleBannerFilter = (event) => {
    setBannerFilter(event.target.value);
  };

  const handleDirectionFilter = (event) => {
    setDirectionFilter(event.target.value);
  };

  const handleSuperColorFilter = (event) => {
    setSuperColorFilter(event.target.value);
  };

  const handleToggleDetails = () => {
    setShowDetails(!showDetails);
  };

  const filteredUnits = units.filter(unit => {
    const matchesSearchTerm = Object.values(unit).some(value =>
      typeof value === 'string' && value.toLowerCase().includes(searchTerm.toLowerCase())
    );
    const matchesColorFilter = colorFilter ? unit.Color === colorFilter : true;
    const matchesSideFilter = sideFilter ? unit.Side === sideFilter : true;
    const matchesAttack_TypeFilter = Attack_typeFilter ? unit.Attack_Type === Attack_typeFilter : true;
    const matchesBannerFilter = bannerFilter ? unit.Banner === bannerFilter : true;
    const matchesDirectionFilter = directionFilter ? unit.Direction === directionFilter : true;
//    const matchesSuperColorFilter = supercolorFilter ? unit.SuperColor === supercolorFilter : true;
    return matchesSearchTerm && matchesColorFilter && matchesSideFilter&& matchesAttack_TypeFilter&& matchesBannerFilter && matchesDirectionFilter && matchesSideFilter;
  });

  return (
    <div className="container">
      <header>
        <Link to="/?view=battle" className="banner-link">Battle</Link>
        <Link to="/?view=assist" className="banner-link">Assist</Link>
      </header>
      <input
        type="text"
        placeholder="Search units..."
        value={searchTerm}
        onChange={handleSearch}
        className="search"
      />
      <div className="filters">
        <select value={colorFilter} onChange={handleColorFilter} className="filter">
          <option value="">All Colors</option>
          <option value="Red">Red</option>
          <option value="Blue">Blue</option>
          <option value="Green">Green</option>
          <option value="Yellow">Yellow</option>
          <option value="Purple">Purple</option>
        </select>
        <select value={sideFilter} onChange={handleSideFilter} className="filter">
          <option value="">All Sides</option>
          <option value="Science">Science</option>
          <option value="Magic">Magic</option>
          <option value="Neutral">Neutral</option>
        </select>
        <select value={Attack_typeFilter} onChange={handleAttack_TypeFilter} className="filter">
          <option value="">All Types</option>
          <option value="Mental">Mental</option>
          <option value="physical">physical</option>
        </select>
        <select value={bannerFilter} onChange={handleBannerFilter} className="filter">
          <option value="">All Banners</option>
          <option value="Fest">Fest</option>
          <option value="Event">Event</option>
          <option value="Permament">Permament</option>
          <option value="Seasonal">Seasonal</option>
          <option value="Collab">Collab</option>
        </select>

      </div>
      <div className="toggle-container">
        <label className="toggle-label">Show Details Directly</label>
        <input
          type="checkbox"
          checked={showDetails}
          onChange={handleToggleDetails}
          className="toggle-checkbox"
        />
      </div>
      {filteredUnits.map((unit) => (
        <div key={unit.Card_Name} className="card">
          <img src={unit.Image} alt={unit.Card_Name} className="image" />
          <h2 className="title">{unit.Card_Name}</h2>
          {showDetails ? (
            <ul className="details-list">
              {Object.entries(unit).map(([key, value]) => (
                <li key={key}><strong>{key.replace(/_/g, ' ')}:</strong> {value}</li>
              ))}
            </ul>
          ) : (
            <Link to={`/details/${dataFile}/${unit.Card_Name}`} className="button">View Details</Link>
          )}
        </div>
      ))}
    </div>
  );
}

function Details({ dataFile }) {
  const { cardName } = useParams();
  const [unit, setUnit] = useState(null);

  useEffect(() => {
    fetch(`/${dataFile}.json`)
      .then(response => response.json())
      .then(data => {
        const unit = data.find(u => u.Card_Name === cardName);
        setUnit(unit);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, [cardName, dataFile]);

  if (!unit) return <div>Loading...</div>;

  return (
    <div className="details-container">
      <div className="image-container">
        <img src={unit.Image} alt={unit.Card_Name} className="image" />
      </div>
      <div className="details-content">
        <h2 className="title">{unit.Card_Name}</h2>
        <ul className="details-list">
          {Object.entries(unit).map(([key, value]) => (
            <li key={key}><strong>{key.replace(/_/g, ' ')}:</strong> {value}</li>
          ))}
        </ul>
        <Link to="/" className="button">Back to Home</Link>
      </div>
    </div>
  );
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomeWrapper />} />
        <Route path="/details/:dataFile/:cardName" element={<DetailsWrapper />} />
      </Routes>
    </Router>
  );
}

function HomeWrapper() {
  const query = useQuery();
  const view = query.get('view') || 'battle';
  return <Home dataFile={view} />;
}

function DetailsWrapper() {
  const { dataFile } = useParams();
  return <Details dataFile={dataFile} />;
}

export default App;